window.addEventListener('load', () => {
    showMore();
});

window.addEventListener('resize', () => {
});

function showMore() {
    const blocks = document.querySelectorAll('.content-with-images-block');
    

    blocks.forEach(block => {
        const more = block.querySelector('.show-more');
        const text = block.querySelectorAll('.description p');

        if (more && text) {
            more.addEventListener('click', ()=> {
                text.forEach(element => {
                    element.classList.add('show');
                    more.classList.add('hidden');
                });
            });
        }
    });
}